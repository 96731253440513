import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/html/safari-css-hacks",
  "date": "2016-07-22",
  "title": "SAFARI CSS HACKS",
  "author": "Sean Amarasinghe",
  "tags": ["development", "css", "html"],
  "featuredImage": "feature.jpg",
  "excerpt": "Applying changes only for Safari has been always a challenging task. Also adding the fact that Safari has changed since version 6.1 makes it even harder. In this article we are going to discuss Safari CSS hacks."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="warn" title="Chrome on iOS" content="if you are using Chrome on iOS (at least in versions 6.1 and newer) and you wonder why none of the hacks seem to be separating Chrome from Safari, it is because the iOS version of Chrome is using the Safari engine. It uses Safari hacks not the Chrome ones." mdxType="Message" />
    <p>{`We start with `}<inlineCode parentName="p">{`Safari 6.1+`}</inlineCode>{` and then versions above it.`}</p>
    <h2>{`Safari Hacks`}</h2>
    <h3>{`Safari 6.1+`}</h3>
    <p>{`By combining multiple other hacks for `}<inlineCode parentName="p">{`6.1+`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0)
{
  @media {
    .safari_only {
        color: #0000FF;
        background-color: #CCCCCC;
    }
  }
}
`}</code></pre>
    <Message type="info" title="" content="Safari 6.1+ (9.0 is the latest version of Safari at this time)" mdxType="Message" />
    <h3>{`Safari 9.0+`}</h3>
    <p>{`One for Safari `}<inlineCode parentName="p">{`9+`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`_::-webkit-:not(:root:root), .safari_only {
 
  color: #0000FF;
  background-color: #CCCCCC;
 
}
`}</code></pre>
    <h3>{`Safari 9`}</h3>
    <p>{`Safari 9 now includes feature detection so we can use that as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@supports (overflow:-webkit-marquee) and (justify-content:inherit)
{
 
  .safari_only {
    color: #0000FF;
    background-color: #CCCCCC;
  }
 
}
`}</code></pre>
    <h2>{`Safari 9.0+ (iOS Only)`}</h2>
    <p>{`Now we can target iOS devices only. As mentioned above, since Chrome on iOS is rooted in Safari, it of course hits that one as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@supports (-webkit-text-size-adjust:none) and (not (-ms-ime-align:auto))
and (not (-moz-appearance:none))
{
  .safari_only {
    color: #0000FF;
    background-color: #CCCCCC;
  }
}
`}</code></pre>
    <h3>{`Safari 9.0+ (non-iOS)`}</h3>
    <p>{`One for Safari `}<inlineCode parentName="p">{`9+`}</inlineCode>{` but not `}<inlineCode parentName="p">{`iOS`}</inlineCode>{` devices:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`_:-webkit-full-screen:not(:root:root), .safari_only {
 
  color: #0000FF;
  background-color: #CCCCCC;
 
}
`}</code></pre>
    <h3>{`Safari 6.1-7.0`}</h3>
    <p>{`Below are hacks that separate `}<inlineCode parentName="p">{`6.1-7.0`}</inlineCode>{`, and `}<inlineCode parentName="p">{`7.1+`}</inlineCode>{`. These also required a combination of multiple hacks in order to get the right result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (-webkit-min-device-pixel-ratio:0) and (min-color-index:0)
{
   .safari_only {(;
 
      color: #0000FF;
      background-color: #CCCCCC;
 
    );}
}
`}</code></pre>
    <h3>{`Safari 7.1+`}</h3>
    <p>{`This one works properly with Safari 9.0 as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`_::-webkit-full-page-media, _:future, :root .safari_only {
 
  color: #0000FF;
  background-color: #CCCCCC;
 
}
`}</code></pre>
    <h3>{`Safari 6.1+ (non-iOS)`}</h3>
    <p>{`Since I have pointed out the way to block iOS devices, here is the modified version of Safari 6.1+ hack that targets non-iOS devices:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{
  @media {
    _:-webkit-full-screen, .safari_only {
        color: #0000FF;
        background-color: #CCCCCC;
    }
  }
}
`}</code></pre>
    <p>{`Here are the basics, again check my test page for lots of specific versions of Chrome, but these cover Chrome in general. Chrome is version 45, Dev and Canary versions are up to version 47 at this time.`}</p>
    <h2>{`Chrome Only Hacks`}</h2>
    <p>{`You might want to add styles to Chrome only and not Safari.`}</p>
    <h3>{`Chrome 29+`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)
{
    .chrome_only {
 
       color: #0000FF;
       background-color: #CCCCCC;
 
    }
}
`}</code></pre>
    <h3>{`Chrome 29+`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`@supports`}</inlineCode>{` feature query works well for Chrome 29+ as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee))
and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none))
{
    .chrome_only {
 
       color: #0000FF;
       background-color: #CCCCCC;
 
    }
}
`}</code></pre>
    <p>{`A modified version of the one we were using for Chrome 28+ is shown next. Safari 9, the coming Firefox browsers, and the Microsoft Edge browser are not picked up with this one.`}</p>
    <p>{`Next, we look at `}<inlineCode parentName="p">{`Chrome 28+`}</inlineCode>{`.`}</p>
    <h3>{`Chrome 28+`}</h3>
    <p>{`This older method below now picks up Safari 9 and the Microsoft Edge browser without the above update.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@supports (-webkit-appearance:none)
{
    .chrome_and_safari {
 
       color: #0000FF;
       background-color: #CCCCCC;
 
    }
}
`}</code></pre>
    <Message type="info" title="" content="The coming versions of `Firefox` and `Microsoft Edge` have added support for multiple `-webkit-` CSS codes in their programming, and both Edge and Safari 9 have added support for @supports feature detection. Chrome and Firefox included @supports previously." mdxType="Message" />
    <h2>{`Chrome 22-28`}</h2>
    <p>{`The block of Chrome versions 22-28 (If needed to support older versions) are also possible to target with a twist on my Safari combo hacks I posted above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and(-webkit-min-device-pixel-ratio:0)
{
    .chrome_only {-chrome-:only(;
       color: #0000FF;
       background-color: #CCCCCC;
    );}
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      